import {
  analyticsEventBase,
  AnalyticsEventBase,
  extendsAnalyticsEventBase,
} from 'kat/lib/sdks/firebase/analyticsEventBase'

type AnalyticsEvent = AnalyticsEventBase<
  | 'Get started: Center'
  | 'Get started: Bottom'
  | 'Begin Onboarding'
  | 'player: initial load'
  | 'player: sign in anonymously start'
  | 'player: sign in anonymously success'
  | 'player: create card start'
  | 'player: game full'
  | 'player: create card success'
  | 'player: retry max reload'
  | 'player: create card error'
  | 'player: get claim'
  | 'player: get claim error'
  | 'player: submit CardPlayerNameForm'
  | 'player: select emoji'
  | 'player: submit button'
  | 'player: game objective'
  | 'player: game objective: next'
  | 'player: lets play'
  | 'player: square: upload'
  | 'player: square upload: old error'
  | 'player: square upload: dimension error'
  | 'player: square upload: type error'
  | 'player: square upload: size error'
  | 'highlight: initial load'
  | 'highlight: sign in anonymously start'
  | 'highlight: sign in anonymously success'
  | 'highlight: create permission'
  | 'highlight: create permission success'
  | 'highlight: get claim'
  | 'highlight: get claim error'
  | 'highlight: retry max reload'
  | 'Go back'
>

const reportImediatelyEvents: AnalyticsEvent[] = ['player: retry max reload']

interface Props {
  event: AnalyticsEvent
  params?: {
    currentUserId?: string | null | undefined // redundant with user id in Mixpanel but still useful
    gameId?: string | null | undefined
    templateType?: string
  }
}

extendsAnalyticsEventBase(({ event, eventParams }) => {
  const eventAs = event as AnalyticsEvent
  if (reportImediatelyEvents.includes(eventAs)) {
    window.mixpanel?.track?.(event as string, eventParams, {
      send_immediately: true,
    })
  } else {
    window.mixpanel?.track?.(event as string, eventParams)
  }
})

export const logAnalyticsEvent = ({ event, params }: Props) => {
  analyticsEventBase({ event, eventParams: params })
}
